<script>
import Swal from 'sweetalert2';
// import VIcon from '../components/Icon.vue'
import VCards from '../components/Cards.vue';
import VSteps from '../components/Steps.vue';

export default {
  components: {
    // VIcon,
    VCards,
    VSteps,
  },
  data() {
    return {
      billetsOpeneds: [],
      idContaCartaoDebitado: null,
      apiData: {
        receipts: []
      },
      screenIsPhone: false,
      loading: false
    };
  },
  watch: {
    async idContaCartaoDebitado(val) {
      if (val) await this.getStatements()
    }
  },
  methods: {
    goToPayment() {
      if (this.loading) return;
      if (this.apiData.hasPaymentsAvailable) {
        this.$router.push({ name: 'payment', query: { c: this.idContaCartaoDebitado } });
      } else {
        Swal.fire({
          title: 'Pagamentos',
          text: `Seus ${(this.apiData.paymentOperationsAvailable || 0) + (this.apiData.bonusAvailable || 0) + this.apiData.paidPayments} pagamentos deste mês esgotaram. Para saber mais consulte nossa central.`,
          type: 'info',
          confirmButtonText: 'Ok'
        })
      }
    },
    async getStatements() {
      this.loading = true
      await this.$http.get(`/transfers/payments/statements/${this.idContaCartaoDebitado}`)
        .then((res) => {
          this.apiData = res.data
        })
        .catch(() => {
          this.apiData = {
            receipts: []
          }
          this.idContaCartaoDebitado = null
          Swal.fire({
            title: 'Erro',
            text: 'Não foi possível carregar as informações dos seus pagamentos disponíveis.',
            type: 'error',
            confirmButtonText: 'Ok'
          })
        })
        .then(() => this.$store.commit('pageLoading', false))
      this.loading = false
    },
    nextStep(card, balance) {
      if (balance >= 1) {
        if (card.idProduto <= 8) this.$router.push({ name: 'payment', query: { c: card.idConta } });
        this.idContaCartaoDebitado = card.idConta.toString()
      } else {
        Swal.fire({
          title: 'Atenção',
          text: 'É necessário o saldo mínimo de R$ 1,00 para pagamento de boletos.',
          type: 'info',
          confirmButtonText: 'Ok'
        })
      }
    },
    async permitedOperation() {
      await this.$http.get('/utils/check-restricao')
        .catch((data) => {
          console.log('data', data)
          // show message and redirect to home
          Swal.fire({
            type: 'error',
            title: data.message || 'Transação não permitida nesse horário',
            confirmButtonText: 'Ok',
            customClass: {
              title: 'px-5'
            },
          }).then(() => {
            this.$router.push('/')
          }).finally(() => {
            this.$router.push('/')
          })
        })
    },
  },
  computed: {
    availableCards() {
      return this.$store.state.availableCards;
    }
  },

  async mounted() {
    await this.permitedOperation()
    this.screenIsPhone = window.innerWidth < 768;
  },
  // beforeCreate() {
  //   Swal.fire({
  //     html: `
  //       <div style='text-align: left;color: #6c757d!important; font-size: medium;'>
  //         <h3 style='font-size: 1.75rem;'>Olá!</h3>
  //           Operação não disponível no momento. Agradecemos a compreensão.
  //           <br />
  //           <br />
  //           <br />
  //           <center>Equipe OnlyPay</center>
  //       </div>
  //     `,
  //     type: 'warning',
  //     showConfirmButton: true,
  //     confirmButtonText: 'Voltar',
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //     allowEnterKey: false
  //   })
  //   this.$router.push('/')
  // }
  // mounted() {
  //   Swal.fire(
  //     'AVISO!',
  //     `<p style="text-align:left;">
  //     Caro Usuário,<br><br>
  //     Site disponível para consulta de saldo e desbloqueio do seu cartão.<br><br>
  //     Demais funcionalidades como pagamento de boleto, transferência entre cartões, transferência e recarga de celular estarão disponíveis em breve.<br><br>
  //     Equipe OnlyPay.
  //     </p>`,
  //     'info'
  //   )
  // }
};
</script>

<template>
  <div class="recharge-card">
    <v-steps :current="idContaCartaoDebitado ? 2 : 1" :length="2" class="transfer__header"/>
    <!-- check media is phone -->
    <div>
      <p>
        Esse pagamento será tarifado no valor de R$ 1,00 por transação e será descontado do saldo do seu cartão.
        <br>
        <br>
        Atenção: Ao realizar pagamentos certifique-se do horário permitido (08h até às 16h).
        Pagamentos realizados fora deste horário serão processados no próximo dia útil.
        <br>
        <br>
        Fique atento com a data de vencimento.
        <br>
        <br>
        A partir das 20h até 8h do próximo dia útil não haverá processamento.
        <br>
        <br>
      </p>
    </div>
    <div v-if="!idContaCartaoDebitado">
      <p v-if="this.availableCards" :class="{'recharge-card__text__info': screenIsPhone}">
        <strong>Selecione o cartão que deseja que seja debitado o valor do pagamento.</strong>
      </p>
      <p v-else class="unavailableCards"><strong>Não há cartões disponíveis para este tipo de transação.</strong></p>
      <br>
      <v-cards v-on:click="nextStep" :showActions="false" :showBalance="true"/>
    </div>
    <div v-else class="recharge-card__content">
      <div class="recharge-card__content__form__value">
        <!-- <div class="recharge-card__content__form__value__actions">
          <a @click="()=>{ idContaCartaoDebitado = null; }"
            class="recharge-card__content__form__value__actions__back">
            <v-icon class="recharge-card__content__form__value__actions__back__icon" name="back"/>
            Voltar
          </a>
        </div>
        <div>
          Limite de R$3.000,00 por boleto
        </div>
        <div>
          Você possui {{this.apiData.paymentOperationsAvailable + this.apiData.bonusAvailable || 0}} pagamentos disponíveis.
        </div>
        <div v-if="apiData.receipts.length === 0">
          <p>Não há extratos de pagamentos</p>
        </div>
        <div v-else>
          <table class="col-md-12">
            <thead>
              <tr>
                <th scope="col"> # </th>
                <th scope="col">Data de Pagamento</th>
                <th scope="col">Valor</th>
                <th scope="col">Emissor</th>
              </tr>
            </thead>
            <tbody>
              <tr scope="row" v-for="(item, index) in apiData.receipts" :key="item.id">
                <td>{{index+1}}</td>
                <td>{{item.transactionDate}}</td>
                <td>R$ {{item.amount}}</td>
                <td>{{item.assignor}}</td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <div>
          <button @click="goToPayment()" class="btn-new-billet" :disabled="loading">Pagar uma conta</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>

.unavailableCards {
  color: red;
}
.recharge-card {
  background-color: var(--color-white);
  position: relative;
  @media screen and (max-width: 991px) {
    /* padding-top: 5vh; */
  }
  &__text__info {
    display: block;
    position: absolute;
    margin-top: 60px;
    padding-bottom: 65px;
  }
  &__content {
    padding-top: 4.75rem;
    @media (min-width: 576px) {
      padding-top: 0;
    }
    &__title {
      margin-bottom: 1.5rem;
    }
    &__form {
      @media (min-width: 576px) {
        display: grid;
        grid-column-gap: 1.5rem;
        grid-template-columns: 1fr 1fr;
      }
      &__card {
        @media (min-width: 576px) {
          margin-bottom: 0;
        }
      }
      &__value {
        padding-bottom: 1.5rem;
        position: relative;
        @media (min-width: 576px) {
          /* border-left: .0625rem solid var(--color-grey-light); */
          padding-left: 1.5rem;
        }
        table {
          border: #00000026 1px solid;
          border-radius: 4px;
          padding:0;
          tr {
            th {
              text-align: left;
              font-weight: bold;
              background-color:#ececec4f!important;
              border-bottom: 1px #00000038 solid;
              padding: 10px;
            }
            td {
              padding: 4px 10px;
              img {
                width: 31px;
                margin-top: -4px;
                margin-bottom: -3px;
              }
              &:hover {
                cursor: pointer;
              }
            }
            &:nth-child(even) {
              background-color: #f2f2f2;
            }
            &:hover{
              td {
                background-color: #d1d1d1;
              }
            }
          }
        }
        &__warning {
          font-size: .8125rem;
          margin: 1.5rem 0;
        }
        .btn-new-billet {
          background-color: var(--color-green);
          border-radius: .25rem;
          color: var(--color-white);
          cursor: pointer;
          display: block;
          font-size: .75rem;
          font-weight: bold;
          height: 3.25rem;
          text-transform: uppercase;
          -webkit-transition: background-color .25s;
          transition: background-color .25s;
          width: 100%;
          margin-top: 40px;
        }
        .btn-new-billet:disabled,
        .btn-new-billet[disabled] {
          border: 1px solid #99999957;
          background-color: #cccccc;
          color: #666666;
          cursor: not-allowed;
        }
        &__actions {
          @media (min-width: 576px) {
            /* position: absolute auto 1.5rem 0; */
          }
          &__back {
            color: inherit;
            display: block;
            font-size: .75rem;
            font-weight: bold;
            margin-top: 1rem;
            margin-bottom: 1.5rem;
            text-transform: uppercase;
            @media (min-width: 992) {
              display: none;
            }
            &__icon {
              margin: -.125rem .5rem 0 0;
              size: 1rem .89375rem;
            }
          }
        }
      }
    }
  }
}
</style>
